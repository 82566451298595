import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import * as ERROR from '../../../../constants/error';
import { Helmet } from 'react-helmet';
import Analytics from '../../../common/components/Analytics';
import HtmlContext from '../../../common/context/HtmlContext';
import { withLayoutContext } from '../../../common/context/LayoutContext';
import TitleCard from './TitleCard';
import DropdownMenu from './DropdownMenu';
import PagerBlock from './PagerBlock';
import routes from '../../../common/routes';
import categoryCode2Name from '../../../../common/categoryCode2Name';
import window from 'global';
import { CongestionError } from '../../../common/components/ErrorBoundary';
import dayjs from 'dayjs';
import { getCardResizeImage } from '../../../../common/preloadImages';
import TaboolaWidget from '../../../common/components/TaboolaWidget';
import { validatePageIndexWithinRange, validateDateParameters } from '../../../common/utils/validateQueryParameters';

const PERPAGE = 10;
const RESET_BTN_TEXT = 'リセット';
const CLOSE_BTN_TEXT = '閉じる';
const SEARCH_PLACEHOLDER = 'キーワードを入力';
const DISP_CALENDAR_CLASSNAME = 'calendar-disp-year';
const KEYWORD_LIMIT_LENGTH = 30;

/**
 * 表示中のカレンダーから、翌年月が選択可能か判定する
 * @param {object} instance 
 */
const setNextYear = instance => {
  // 表示年の更新
  const dispYear = instance.calendarContainer.querySelector(`.${DISP_CALENDAR_CLASSNAME}`);
  dispYear.textContent = `${instance.currentYear}年`;

  // 次年選択可能か判定処理
  const disabledClassName = 'arrow-disabled';
  const arrowUpEls = instance.calendarContainer.getElementsByClassName('arrowUp');
  if (!arrowUpEls || !arrowUpEls?.length) return; // 要素が取得できない or 存在しない場合: エラー回避

  const currentYear = dayjs(new Date()).year();
  const targetClassList = arrowUpEls[0].classList;
  if (instance.currentYear < currentYear) {
    return targetClassList.remove(disabledClassName);
  }

  if (!targetClassList.contains(disabledClassName)) {
    targetClassList.add(disabledClassName);
  }
}

/**
 * カレンダー画面「年」表示用のDOM生成
 * @param {object} instance 
 */
const setDispYear = instance => {
  const inputDispYear = instance.calendarContainer.querySelector('input.cur-year');
  inputDispYear.classList.add('calendar-year-hidden');
  const dispYear = document.createElement('span');
  dispYear.textContent = `${instance.currentYear}`;
  dispYear.classList.add(DISP_CALENDAR_CLASSNAME);
  inputDispYear.parentNode.insertBefore(dispYear, inputDispYear.nextSibling); 
}

class SearchContent extends React.Component {
  static contextTypes = {
    routeHandler: PropTypes.object,
    spMode: PropTypes.bool,
    history: PropTypes.object,
    models: PropTypes.object,
    topText: PropTypes.string,
  };

  static getPaths = function (models, options, props) {
    let paths = [];
    const indexes = function (models, options, props) {
      const page = _.get(props, 'routeHandler.query.p');
      let from, to;
      if (page) {
        from = page * PERPAGE - PERPAGE;
        to = page * PERPAGE - 1;
      }
      return {
        from: from || (props && props.fromNum) || 0,
        to: to
          ? to
          : props && props.toNum
          ? props.toNum
          : options && options.numTitles
          ? options.numTitles + (props.fromNum || 0)
          : PERPAGE - 1,
      };
    };

    const rootPath = this.getRootPath(models, options, props);

    paths = paths.concat([
      rootPath.concat([
        indexes(models, options, props),
        ['article_id', 'title', 'thumbnail', 'publish_start_date', 'first_publish_date', 'created_at'],
      ]),
      rootPath.concat(['count']),
      rootPath.concat(['current_page']),
      rootPath.concat(['error']),
      ['link', _.get(props.routeHandler, 'params.companyCode', 'ntv'), 'category_menu'],
    ]);

    return paths;
  };

  static getRootPath = function (models, options, props) {
    const filter = {
      category: _.get(props, 'routeHandler.query.sc'),
      term: _.get(props, 'routeHandler.query.q'),
      from: _.get(props, 'routeHandler.query.f'),
      to: _.get(props, 'routeHandler.query.t'),
    };
    return [
      'article',
      'search',
      'byTerm',
      filter.term || '',
      filter.category || '',
      filter.from || '',
      filter.to || '',
    ];
  };

  static getPrefetchPaths = function (models, options, props) {
    return this.getPaths(models, options, props);
  };

  static afterPrefetch = function (models, options, props) {
    const self = this;
    return (prefetchResult) => {
      const rootPath = self.getRootPath(null, null, props);
      const item = _.get(prefetchResult, ['json'].concat(rootPath));
      if (!item) return { error: ERROR.NOTFOUND };

      if (_.get(item, 'error')) return { error: ERROR.CONGESTIONERROR };

      // ページクエリ不正チェック
      const totalCount = _.get(item, 'count', null);
      if (validatePageIndexWithinRange(props, totalCount, PERPAGE)) return { error: ERROR.NOTFOUND };

      return null;
    };
  };

  constructor(props, context) {
    super(props, context);

    this.companyCode = _.get(props.routeHandler, 'params.companyCode', 'ntv');

    this.searchInputRef = React.createRef();

    this.onClickPager = this.onClickPager.bind(this);
    this.handleSearchTerm = this.handleSearchTerm.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.handleSearchClick = this.handleSearchClick.bind(this);
    this.handleSearchExec = this.handleSearchExec.bind(this);
    this.handleChangeCategory = this.handleChangeCategory.bind(this);

    const rootPath = this.constructor.getRootPath(context.models, null, props);
    this.model = (props.pathEvaluator || props.model.pathEvaluator).batch(100);
    this.totalCount = this.model.getSync(rootPath.concat('count')) || 0;
    this.lastPage = this.totalCount && Math.ceil(this.totalCount / PERPAGE);
    this.items = this.model.getSync(rootPath);
    if (this.items && Object.keys(this.items) && Object.keys(this.items).length) {
      const firstIndex = Object.keys(this.items)[0];
      this.currentPage = Math.ceil(firstIndex / PERPAGE) + 1;
    }
    this.categoryList = this.model.getSync(['link', this.companyCode, 'nnn_category_menu']);

    this.to = routes.search;

    this.search = routes.search;
    const from = _.get(props, 'routeHandler.query.f', '');
    const to = _.get(props, 'routeHandler.query.q', '');
    this.state = {
      dispose: null,
      fetchDataError: null,
      loading: false,
      category: _.get(props, 'routeHandler.query.sc', ''),
      term: _.get(props, 'routeHandler.query.q', ''),
      from: validateDateParameters(from) ? '' : from,
      to: validateDateParameters(to) ? '' : to,
      resultTerm: _.get(props, 'routeHandler.query.q', ''),
    };
  }

  /**
   * カレンダー下部「リセット」「閉じる」ボタン要素・イベント生成
   * @param {string} type start | end
   * @param {object} targetFlatPickr flatpickr object
   */
  createCalendarBtns(type, targetFlatPickr) {
    const closeBtn = document.createElement('div');
    const closeTypeClassName = type === 'start' ? 'date-start-close' : 'date-end-close';
    closeBtn.classList.add(closeTypeClassName, 'calendar-close');
    closeBtn.textContent = CLOSE_BTN_TEXT;

    const resetBtn = document.createElement('div');
    const resetTypeClassName = type === 'start' ? 'date-start-reset' : 'date-end-reset';
    resetBtn.classList.add(resetTypeClassName, 'calendar-reset');
    resetBtn.textContent = RESET_BTN_TEXT;

    const wrapper = document.createElement('div');
    wrapper.className = 'calendar-btns-wrapper';
    wrapper.appendChild(resetBtn);
    wrapper.appendChild(closeBtn);
    targetFlatPickr.innerContainer.appendChild(wrapper);

    // add click events
    jQuery(`.${resetTypeClassName}`).on('click', () => {
      targetFlatPickr.clear();
      targetFlatPickr.close();
    });
    jQuery(`.${closeTypeClassName}`).on('click', () => targetFlatPickr.close());
  }

  initCalender() {
    const self = this;

    const init = () => {
      const startCalender = document.querySelectorAll('.dateStart');
      const endCalender = document.querySelectorAll('.dateEnd');
      self.startFlatPickr =
        startCalender &&
        self.loadedFlatpickr &&
        flatpickr &&
        flatpickr(startCalender[0], {
          dateFormat: "Y-m-d",
          locale: 'ja',
          disableMobile: 'true',
          maxDate: new Date(),
          defaultDate: _.get(self.state, 'from'),
          position: 'below',
          onChange: function (selectedDates, dateStr, instanse) {
            if (_.get(self, 'state.beforeFrom') !== dateStr) {
              self.setState({ from: dateStr, beforeFrom: _.get(self.state, 'from') });
              self.handleSearchExec({from: dateStr});
            }
          },
          onOpen: function(selectedDates, dateStr, instance) {
            setNextYear(instance);
          },
          onMonthChange: function(selectedDates, dateStr, instance) {
            setNextYear(instance);
          },
          onYearChange: function(selectedDates, dateStr, instance) {
            setNextYear(instance);
          },
          onReady: function(selectedDates, dateStr, instance) {
            setDispYear(instance);
          },
        });
      if (self.startFlatPickr && typeof jQuery !== 'undefined') {
        jQuery('.flatpickr-calendar').addClass('left-position');
      }
      if (_.get(self.startFlatPickr, 'innerContainer') && typeof jQuery !== 'undefined') {
        self.createCalendarBtns('start', self.startFlatPickr);
      }
      self.endFlatPickr =
        endCalender &&
        self.loadedFlatpickr &&
        flatpickr &&
        flatpickr(endCalender[0], {
          locale: 'ja',
          disableMobile: 'true',
          maxDate: new Date(),
          defaultDate: _.get(self.state, 'to'),
          position: 'below',
          onChange: function (selectedDates, dateStr, instanse) {
            if (_.get(self, 'state.beforeTo') !== dateStr) {
              self.setState({ to: dateStr, beforeTo: _.get(self.state, 'to') });
              self.handleSearchExec({to: dateStr});
            }
          },
          onOpen: function(selectedDates, dateStr, instance) {
            setNextYear(instance);
          },
          onMonthChange: function(selectedDates, dateStr, instance) {
            setNextYear(instance);
          },
          onYearChange: function(selectedDates, dateStr, instance) {
            setNextYear(instance);
          },
          onReady: function(selectedDates, dateStr, instance) {
            setDispYear(instance);
          },
        });
      if (typeof jQuery !== 'undefined' && _.get(self.endFlatPickr, 'innerContainer')) {
        self.createCalendarBtns('end', self.endFlatPickr);
      }
    };

    const scripts = document.getElementsByTagName('script');
    const scriptFirst = _.get(scripts, '0');
    if (_.findIndex(scripts, { src: `${window.location.origin}/jquery-3.6.0.min.js` }) < 0) {
      const script = document.createElement('script');
      script.type = 'text/javascript';
      script.src = '/jquery-3.6.0.min.js';
      scriptFirst.parentNode.insertBefore(script, scriptFirst);
      script.onload = () => {
        init();
      };
    } else {
      init();
    }
  }

  handleChangeCategory(category) {
    this.handleSearchExec({ category });
  }

  handleSearchExec(conditions) {
    let { category, term, from, to } = Object.assign({}, this.state, conditions);
    const beforeTerm = term;
    term = _.get(this.searchInputRef, 'current.value');

    if (category === 'undefined') {
      category = '';
    }

    const query = {
      sc: category,
      q: term,
      f: from,
      t: to,
      p: _.get(this.props, 'routeHandler.query.p'),
    };

    if (
      _.get(this.state, 'category') !== query.sc ||
      _.get(this.state, 'beforeFrom') !== query.f ||
      _.get(this.state, 'beforeTo') !== query.t ||
      beforeTerm !== query.q
    ) {
      query.p = 1;
    }
    const url = routes.search.makePath({}, query);
    window.location.href = url;
  }

  handleSearchClick(e) {
    e.preventDefault();
    this.handleSearchExec();
  }

  handleKeyDown(e) {
    if (e.which === 13) {
      this.handleSearchExec();
    }
  }

  handleSearchTerm(e) {
    if (this.onChangeTermTimeId) {
      clearTimeout(this.onChangeTermTimeId);
      delete this.onChangeTermTimeId;
    }
    this.onChangeTermTimeId = setTimeout(() => {
      this.setState({ term: this.searchTerm, beforeTerm: _.get(this.state, 'term') });
      clearTimeout(this.onChangeTermTimeId);
      delete this.onChangeTermTimeId;
    }, 100);
  }

  onClickPager(page) {
    if (page) {
      const props = Object.assign({}, this.props, {
        fromNum: page * PERPAGE - PERPAGE,
        toNum: page * PERPAGE - 1,
      });
      this.fetchData(props, this.context);
    }
  }

  componentDidMount() {
    this._isMounted = true;
    // カレンダー開始日・終了日 state 初期値
    const from = _.get(this.props, 'routeHandler.query.f', '');
    const to = _.get(this.props, 'routeHandler.query.q', '');
    this.setState({beforeFrom: validateDateParameters(from) ? '' : from});
    this.setState({beforeTo: validateDateParameters(to) ? ' ': to});

    const self = this;
    const scripts = document.getElementsByTagName('script');
    if (_.findIndex(scripts, { src: 'https://cdn.jsdelivr.net/npm/flatpickr' }) < 0) {
      const script = document.createElement('script');
      const scriptLang = document.createElement('script');
      scriptLang.type = 'text/javascript';
      scriptLang.src = 'https://npmcdn.com/flatpickr/dist/l10n/ja.js';
      script.type = 'text/javascript';
      script.src = 'https://cdn.jsdelivr.net/npm/flatpickr';
      document.body.appendChild(script);
      document.body.appendChild(scriptLang);
      script.onload = () => {
        scriptLang.onload = () => {
          self.loadedFlatpickr = true;
          self.initCalender();
        };
      };
    } else {
      self.loadedFlatpickr = true;
      self.initCalender();
    }

    this.fetchData();
  }

  componentWillUnmount() {
    this._isMounted = false;
    if (this.state.dispose) {
      this.state.dispose();
    }
    if (this.startFlatPickr) {
      this.startFlatPickr.destroy();
    }
    if (this.endFlatPickr) {
      this.endFlatPickr.destroy();
    }
    this.setState({
      fetchDataError: null,
      dispose: null,
      loading: false,
      category: '',
      term: '',
      from: '',
      to: '',
      resultTerm: '',
    });
  }

  isChangeQuery(prevProps, props) {
    return (
      _.get(prevProps, 'routeHandler.query.q') !== _.get(props, 'routeHandler.query.q') ||
      _.get(prevProps, 'routeHandler.query.sc') !== _.get(props, 'routeHandler.query.sc') ||
      _.get(prevProps, 'routeHandler.query.f') !== _.get(props, 'routeHandler.query.f') ||
      _.get(prevProps, 'routeHandler.query.t') !== _.get(props, 'routeHandler.query.t') ||
      _.get(prevProps, 'routeHandler.query.p') !== _.get(props, 'routeHandler.query.p')
    );
  }

  componentDidUpdate(prevProps, prevState) {
    this.initCalender();
    if (this.isChangeQuery(prevProps, this.props)) {
      if (typeof window !== 'undefined') {
        const dataLayer = window.dataLayer || [];
        let url = window.location.pathname.substring(1);
        if (window.location.search.length > 0) {
          url += window.location.search;
        }
        const title = `"${_.get(this.props, 'routeHandler.query.q'), ''}"の検索結果`;
        dataLayer.push({
          event: 'pageview',
          page: {
            url,
            title: title,
          },
        });
      }
      if (
        this.startFlatPickr &&
        _.get(prevProps, 'routeHandler.query.f') !== _.get(this.props, 'routeHandler.query.f')
      ) {
        const date = _.get(this.props, 'routeHandler.query.f') ? dayjs(this.props.routeHandler.query.f).format() : '';
        if (date) {
          this.startFlatPickr.setDate(date);
        } else if (_.get(this.startFlatPickr, 'element.value')) {
          this.startFlatPickr.clear();
        }
      }
      if (this.endFlatPickr && _.get(prevProps, 'routeHandler.query.t') !== _.get(this.props, 'routeHandler.query.t')) {
        const date = _.get(this.props, 'routeHandler.query.t') ? dayjs(this.props.routeHandler.query.t).format() : '';
        if (date) {
          this.endFlatPickr.setDate(date);
        } else if (_.get(this.endFlatPickr, 'element.value')) {
          this.endFlatPickr.clear();
        }
      }
      this.fetchData();
    }
  }

  fetchData(props = this.props, context = this.context) {
    const paths = this.constructor.getPaths(context.models, {}, props);
    let rootPath = this.constructor.getRootPath(context.models, {}, props);
    if (rootPath) rootPath = rootPath.join('.');
    const evaluator = this.model.fetch(paths);
    this.state.dispose = evaluator.dispose;

    evaluator
    .then((res) => {
      this.items = _.get(res, `json.${rootPath}`, {});
        if (this.items) {
          const firstIndex = Object.keys(this.items)[0];
          this.currentPage = Math.ceil(firstIndex / PERPAGE) + 1;
          this.totalCount = _.get(this.items, 'count');
          this.lastPage = this.totalCount && Math.ceil(this.totalCount / PERPAGE);
        }

        const from = _.get(props, 'routeHandler.query.f', '');
        const to = _.get(props, 'routeHandler.query.t', '');
        const newState = {
          fetchDataError: _.get(this.items, 'error'),
          dispose: null,
          loading: false,
          category: _.get(props, 'routeHandler.query.sc'),
          term: _.get(props, 'routeHandler.query.q'),
          from: validateDateParameters(from) ? '' : from,
          to: validateDateParameters(to) ? '' : to,
          resultTerm: _.get(props, 'routeHandler.query.q'),
        };

        if (this._isMounted) this.setState(newState);
        else Object.assign(this.state, newState);

      })
      .catch((e) => {
        const newState = {
          fetchDataError: e,
          fetchingMoreRows: undefined,
          dispose: null,
        };
        if (this._isMounted) this.setState(newState);
        else Object.assign(this.state, newState);
      });
  }

  correctCurrentCategoryName() {
    const currentCategoryName = categoryCode2Name(this.state.category, _.get(this.context, 'models.config.data'));
    let categoryNames = _.map(this.categoryList, 'title');
    categoryNames = _.pull(categoryNames, '特集')
    return _.includes(categoryNames, currentCategoryName) ? currentCategoryName : 'カテゴリ'
  }

  render() {
    let spBlock;
    let pcBlock;

    if (this.state.fetchDataError === ERROR.CONGESTIONERROR) {
      if (_.get(this.context, 'isSpaError')) {
        this.context.isSpaError();
      }
      throw new CongestionError();
    }

    let cards = [];
    let items = this.items;
    if (items && _.size(items)) {
      items = _.slice(_.values(items), 0, PERPAGE);

      // preload imageの設定
      const articleImages = _.compact(
        _.map(
          _.filter(items, e => e && e.source_systems),
          itemData => getCardResizeImage({ itemData }, this.context),
        ),
      );
      if (!_.isEmpty(articleImages)) this.props.setPreloadImages(articleImages);
    }
    const domain = _.get(this.context, 'models.hosts.data.domain_url');
    const pageUrl = _.get(this.context, 'models.hosts.data.domain_url') + _.get(this.context, 'routeHandler.url');
    const isEachCompany = _.get(this.context.routeHandler, 'isEachCompany');
    const spMode = _.get(this.context, 'spMode');

    cards = _.compact(
      _.map(items || [], (itemData, index) => {
        if (!itemData || !itemData.source_systems) return;
        return (
          <TitleCard
            key={`search_card_${index}_${itemData.source_systems.id}`}
            itemData={itemData}
            lineLayout
            // lineLayout={!this.context.spMode}
            // default={this.context.spMode}
            searchContent={true}
          />
        );
      }),
    );

    spBlock = (
      <React.Fragment>
        <div className="search-block">
          <div className="search-result">
            {_.get(this.state, 'resultTerm', '') ? (
              <h1 className="search-title">{`“${this.state.resultTerm}”の検索結果`}</h1>
            ) : (
              <h1 className="search-title">{`検索結果`}</h1>
            )}
            <p className="search-number">
              <span>{_.isEmpty(cards) ? 0 : this.totalCount}</span>件
            </p>
          </div>
          <div className="search-input">
            <div className='search-input-text'>
              <input
                ref={this.searchInputRef}
                type="text"
                placeholder={SEARCH_PLACEHOLDER}
                defaultValue={this.state.term}
                onChange={this.handleSearchTerm}
                onKeyDown={this.handleKeyDown}
                maxLength={KEYWORD_LIMIT_LENGTH}
              />
            </div>
            <span className="search-button button primary medium bold radius" onClick={this.handleSearchClick}>
              検索
            </span>
          </div>
          <div className="dropdown-wrapper">
            <DropdownMenu
              categoryTag={true}
              search={true}
              list={this.categoryList}
              current={this.correctCurrentCategoryName()}
              onChange={this.handleChangeCategory}
            />
            <div className="dateStart-block">
              <input className="dateStart" placeholder="開始日" />
            </div>
            <div className="dateStart-block">
              <input className="dateEnd" placeholder="終了日" />
            </div>
          </div>
        </div>
        <div className="searchResults-content">
          {!_.isEmpty(cards) ? (
            <React.Fragment>{cards}</React.Fragment>
          ) : (
            <div className="search-no-item">キーワードに該当する記事はありません</div>
          )}
          <div key={`search_taboola_${Date.now()}`} className='news-list-item'>
            <TaboolaWidget target="below1" />
          </div>
        </div>
        {this.lastPage > 1 && !_.isEmpty(cards) && (
          <div className="btn-block">
            <PagerBlock
              keyPrefix="search_list_pager"
              currentPage={this.currentPage}
              lastPage={this.lastPage}
              to={this.to}
              queryParams={_.get(this.props, 'routeHandler.query')}
            />
          </div>
        )}
      </React.Fragment>
    );

    pcBlock = (
      <React.Fragment>
        <div className="search-block">
          <div className="search-result">
            {_.get(this.state, 'resultTerm', '') ? (
              <h1 className="search-title">{`“${this.state.resultTerm}”の検索結果`}</h1>
            ) : (
              <h1 className="search-title">{`検索結果`}</h1>
            )}
            <p className="search-number">
              <span>{_.isEmpty(cards) ? 0 : this.totalCount}</span>件
            </p>
          </div>
          <div className="search-input">
            <input
              ref={this.searchInputRef}
              type="text"
              placeholder={SEARCH_PLACEHOLDER}
              defaultValue={this.state.term}
              onChange={this.handleSearchTerm}
              onKeyDown={this.handleKeyDown}
              maxLength={KEYWORD_LIMIT_LENGTH}
            />
            <i className="fa-search search-icon" onClick={this.handleSearchClick} />
          </div>
          <div className="dropdown-wrapper">
            <DropdownMenu
              categoryTag={true}
              search={true}
              list={this.categoryList}
              current={this.correctCurrentCategoryName()}
              onChange={this.handleChangeCategory}
            />
            <div className="dateStart-block">
              <input className="dateStart" placeholder="開始日" />
            </div>
            <div className="dateStart-block">
              <input className="dateEnd" placeholder="終了日" />
            </div>
          </div>
        </div>
        <div className="searchResults-content">
          {!_.isEmpty(cards) ? (
            <React.Fragment>{cards}</React.Fragment>
          ) : (
            <div className="search-no-item">キーワードに該当する記事はありません</div>
          )}
          <div key={`search_taboola_${Date.now()}`}>
            <TaboolaWidget target="below1" />
          </div>
        </div>
        {this.lastPage > 1 && !_.isEmpty(cards) && (
          <div className="btn-block">
            <PagerBlock
              keyPrefix="search_list_pager"
              currentPage={this.currentPage}
              lastPage={this.lastPage}
              to={this.to}
              queryParams={_.get(this.props, 'routeHandler.query')}
              showBtn={4}
            />
          </div>
        )}
      </React.Fragment>
    );

    const topText = _.get(this.context, 'topText');
    const pageIndex = this.currentPage > 1 ? `${this.currentPage}ページ目 ` : '' ;
    const title = `${pageIndex}「${_.get(this.state, 'resultTerm', '')}」のニュース${this.totalCount || 0}件｜${topText}`;
    const description = `「${_.get(this.state, 'resultTerm', '')}」のニュース検索結果。日本テレビ系NNN30局のニュースサイト「日テレNEWS NNN」は政治、経済、国際、社会、スポーツ、カルチャー・エンタメ・芸能、ライフなど、ニュース速報のほか天気、地震、津波、台風など防災・気象情報を配信しています。`

    const query = _.omit(_.get(this.props, 'routeHandler.query'), ['f', 'p', 'sc', 't']);
    let canonical = `${_.get(this.props, 'routeHandler.path')}`;

    // １ページ目以降はページ数もカノニカルに挿入する
    if (this.currentPage > 1) {
      const _query = `?p=${this.currentPage}` + `&q=${_.get(query, 'q')}`;
      canonical += _query;
    } else {
      canonical += `?q=${_.get(query, 'q')}`;
    }

    return (
      <React.Fragment>
        <HtmlContext.Consumer>
          {({ shortTitle }) => {
            const metas = [];

            metas.push({ property: 'og:title', content: shortTitle(title) });
            metas.push({ property: 'og:type', content: 'article' });
            metas.push({ name: 'description', content: description });
            metas.push({ property: 'og:description', content: description });
            metas.push({ name: 'robots', content: 'noindex,nofollow' });

            const linkTags = this.props.getPreloadImages();

            return <Helmet title={title} meta={metas} link={linkTags} />;
          }}
        </HtmlContext.Consumer>
        {this.context.spMode ? spBlock : pcBlock}
        <Analytics pageTitle={title} path={canonical} env={_.get(this.context, 'models.config.data.env')} />
      </React.Fragment>
    );
  }
}
const root = withLayoutContext(SearchContent);
root.getPaths = SearchContent.getPaths;
root.getRootPath = SearchContent.getRootPath;
root.getPrefetchPaths = SearchContent.getPrefetchPaths;
root.afterPrefetch = SearchContent.afterPrefetch;
export default root;

import _get from 'lodash/get';

// 自然数（0を含まない正の整数）
const INTEGER_REGEXP = /^[1-9][0-9]*$/;
const DATE_STRING_REGEXP = /^(2[0-9]{3})-(0[1-9]|1[0-2])-(0[1-9]|1[0-9]|2[0-9]|3[0-1])$/

/**
 * パラメータクエリ「p」の妥当性チェック
 * - 最大ページ数より大きいのページ数が入っていないか
 * - 自然数以外が値に入っていないか
 */
export const validatePageIndexWithinRange = (props, totalCount, partition) => {
  const queryP = _get(props, 'routeHandler.query.p');
  const lastPage = totalCount && Math.ceil(totalCount / partition);

  // totalCount0かつ、p無しまたはp=1のときはスルー
  if (totalCount === 0 && typeof queryP === 'undefined' || queryP === '1') return false;

  // 最大ページ数が取れない、pが空文字はエラー
  if (!lastPage || queryP === '') return true; 

  // pを取れなかったらページ指定なしなのでスルー
  if (queryP) {
    return isNaN(queryP) ?
    true : queryP.match(INTEGER_REGEXP) ?
    parseInt(queryP) > lastPage :
    true;
  }

  return false;
}

/**
 * クエリパラメーター「from」「to」の妥当性チェック
 */
export const validateDateParameters = (dateString) => {
  if(!dateString) return true;

  if(!dateString.match(DATE_STRING_REGEXP)) return true;

  return false;
}

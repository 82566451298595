import classnames from 'classnames';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import ClipboardJS from 'clipboard';
import { withModalContext } from '../../../common/context/ModalContext';

class Share extends Component {
  static propTypes = {
    item: PropTypes.object
  };

  static contextTypes = {
    models: PropTypes.object,
    routeHandler: PropTypes.object
  };

  constructor(props, context) {
    super(props, context);
    this.clipboardOut = this.clipboardOut.bind(this);
    this.handleClickEventForShareBlock = this.handleClickEventForShareBlock.bind(this);
    this.onClickShareButton = this.onClickShareButton.bind(this);
    this.url = _.get(this.context, 'routeHandler.url');
    let host = _.get(context.models, 'hosts.data.ssr_host');
    host = host && host.replace('127.0.0.1', 'localhost');
    this.url = `${host}${this.url}`;
    this.title = _.get(this.props, 'title');
    this.shareBtnRef = React.createRef();
    this.shareBlockRef = React.createRef();

    this.state = {
      isOpen: false
    };
  }

  componentDidMount() {
    this._isMounted = true;
    this.clipboard = new ClipboardJS('.share-list-link');
    this.clipboard.on('success', function(e) {
      if (e && e.trigger && e.trigger.classList) {
        e.trigger.classList.add('tooltipped');
        e.trigger.classList.add('tooltipped-s');
        e.trigger.classList.add('tooltipped-no-delay');
      }
    });
    // モーダルの制御のために、クリックイベントを検知
    document.addEventListener('click', this.handleClickEventForShareBlock);
  }

  componentWillUnmount() {
    this._isMounted = false;
    document.removeEventListener('click', this.handleClickEventForShareBlock)
  }

  clipboardOut(e) {
    if (e && e.target && e.target.classList) {
      e.target.classList.remove('tooltipped');
      e.target.classList.remove('tooltipped-s');
      e.target.classList.remove('tooltipped-no-delay');
    }
  }

  onClickShareButton() {
    this.setState({ isOpen: !_.get(this.state, 'isOpen') });
  }

  /**
   * シェアブロック以外の箇所をクリックしたときに、シェアブロックを閉じる
   */
  handleClickEventForShareBlock(e) {
    if(!this.shareBlockRef.current.contains(e.target) && 
    !this.shareBtnRef.current.contains(e.target)) {
      this.setState({ isOpen: false });
    }
  }

  render() {
    const twitterText = `${this.title.replace(/#/g, '＃').replace(/@/g, '＠')}｜日テレNEWS NNN`;
    return (
      <div className="share-contents">
        <button ref={this.shareBtnRef} className="share-btn" onClick={this.onClickShareButton}>
          {/* iOSでアイコンが小さくなるのでその対策としてタグの属性widthとheightを指定 */}
          <img src="/images/svg/share.svg" alt="共有" width="15px" height="15px"/>
        </button>
        <ul ref={this.shareBlockRef} 
          className={classnames('share-block', { 'open' : this.state.isOpen })}
        >
          <li className="share-list">
            <a
              className="share-list-link"
              href={`https://twitter.com/intent/tweet?text=${encodeURI(twitterText)}&url=${encodeURI(this.url)}`}
              target="_blank"
              rel="noopener noreferrer"
              onClick={this.onClickShareButton}
            >
              <div className="share-icon-img-wrapper">
                <img className="twitter" src="/images/svg/x.svg" alt="twitter" />
              </div>
              <span>ポスト</span>
            </a>
          </li>
          <li className="share-list">
            <a
              className="share-list-link"
              href={`https://timeline.line.me/social-plugin/share?url=${this.url}`}
              target="_blank"
              rel="noopener noreferrer"
              onClick={this.onClickShareButton}
            >
              <div className="share-icon-img-wrapper">
                <img className="line" src="/images/svg/line.svg" alt="line" />
              </div>
              <span>送る</span>
            </a>
          </li>
          <li className="share-list">
            <a
              className="share-list-link"
              href={`https://www.facebook.com/sharer/sharer.php?u=${this.url}`}
              target="_blank"
              rel="noopener noreferrer"
              onClick={this.onClickShareButton}
            >
              <div className="share-icon-img-wrapper">
                <img className="facebook" src="/images/svg/facebook.svg" alt="facebook" />
              </div>
              <span>シェア</span>
            </a>
          </li>
          <li className="share-list">
            <a
              className="share-list-link"
              href={`https://b.hatena.ne.jp/entry/panel/?url=${this.url}`}
              target="_blank"
              rel="noopener noreferrer"
              onClick={this.onClickShareButton}
            >
              <div className="share-icon-img-wrapper">
                <img className="hatena" src="/images/svg/hatena.svg" alt="hatena" />
              </div>
              <span>ブックマーク</span>
            </a>
          </li>
          <li className="share-list">
            <span
              className="share-list-link link"
              onBlur={this.clipboardOut}
              onMouseLeave={this.clipboardOut}
              data-clipboard-text={this.url}
              aria-label="URLをコピーしました"
            >
              <i className="fa-link" />
              <span>URLをコピー</span>
            </span>
          </li>
        </ul>
      </div>
    );
  }
}

export default withModalContext(Share);

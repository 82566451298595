import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { withModalContext } from '../../../common/context/ModalContext';
import classnames from 'classnames';
import MainViewLink from '../../../common/components/MainViewLink';

class PagerBlock extends Component {
  static propTypes = {
    calendar: PropTypes.bool,
    categoryTag: PropTypes.bool,
    search: PropTypes.bool,
    currentPage: PropTypes.number,
    lastPage: PropTypes.number,
    keyPrefix: PropTypes.string,
    onClickPager: PropTypes.func,
    showBtn: PropTypes.number,
  };

  static defaultProps = {
    currentPage: 1,
    lastPage: 5,
    nofollowThreshold: null
  };

  static contextTypes = {
    spMode: PropTypes.bool,
  };

  constructor(props, context) {
    super(props, context);

    this.pagerClick = this.pagerClick.bind(this);
    this.prevBtnClick = this.prevBtnClick.bind(this);
    this.nextBtnClick = this.nextBtnClick.bind(this);

    this.state = {};
  }

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  pagerClick(page) {
    if (this.props.onClickPager && this.props.currentPage !== page) {
      this.props.onClickPager(page);
    }
  }

  prevBtnClick(e) {
    if (this.props.onClickPager && this.props.currentPage > 1) {
      this.props.onClickPager(this.props.currentPage - 1);
    }
  }

  nextBtnClick(e) {
    if (this.props.onClickPager && this.props.currentPage < this.props.lastPage) {
      this.props.onClickPager(this.props.currentPage + 1);
    }
  }

  render() {
    let items = [];
    let isDisplayBackClump = false;
    let isDisplayFrontClump = false;
    let baseParams = _.get(this.props, 'queryParams', {});
    let prevParams, nextParams;
    const to = _.get(this.props, 'to');
    const params = _.get(this.props, 'params', '');
    if (baseParams) {
      prevParams = Object.assign({}, baseParams);
      prevParams = _.set(prevParams, 'p', this.props.currentPage - 1);
      nextParams = Object.assign({}, baseParams);
      nextParams = _.set(nextParams, 'p', this.props.currentPage + 1);
    } else {
      prevParams = { p: this.props.currentPage - 1 };
      nextParams = { p: this.props.currentPage + 1 };
    }

    let viewStart = this.context.spMode ? this.props.currentPage - 1 : this.props.currentPage - 2;
    if (viewStart > this.props.lastPage - 3) {
      viewStart -= 1;
    }
    if (this.props.currentPage === this.props.lastPage) {
      viewStart = this.context.spMode ? this.props.currentPage - 3 : this.props.currentPage - 4;
    }
    if (!this.context.spMode && viewStart === this.props.lastPage - 3) {
      viewStart -= 1;
    }

    let viewEnd = this.props.currentPage + 2;
    if (this.context.spMode && this.props.currentPage === 1) {
      viewEnd += 1;
    }
    if (!this.context.spMode) {
      if (this.props.currentPage === 1) {
        if (this.props.showBtn === 4) {
          viewEnd += 1;
        } else {
          viewEnd += 2;
        }
      } else if (this.props.currentPage === 2) {
        viewEnd += 1;
      }
    }

    /**
     * 3ページ目以降は1ページへのボタンを表示
     * [前へ][1]...[n-2][n-1][n][n+1][n+2][次へ]
     */
    if (viewStart > 1) {
      let classes = 'pager-number';
      let queryParams = Object.assign({}, baseParams);
      queryParams = _.set(queryParams, 'p', 1);
      items.push(
        <MainViewLink key={`${this.props.keyPrefix}1`} className={classes} to={to} params={params} query={queryParams}>
          <span className="number">1</span>
        </MainViewLink>,
      );
      if (viewStart > 2) {
        items.push(<p key={`PagerBlock-${viewStart}`} className={classes + ' ellipsis-dot'}>...</p>);
      }
    }

    // 間の数字ボタンの生成
    for (let i = 1; i <= this.props.lastPage; i++) {
      let classes = 'pager-number';
      if (i === this.props.currentPage) {
        classes += ' disabled';
      }
      const linkProps = {};
      // nofollow閾値以上のページはリンクをnofollow
      if (_.isNumber(_.get(this.props, "nofollowThreshold")) && this.props.nofollowThreshold <= i) {
        linkProps.rel = 'nofollow';
      }
      let queryParams = Object.assign({}, baseParams);
      queryParams = _.set(queryParams, 'p', i);
      if (this.context.spMode && this.props.lastPage > 4) {
        if (i >= viewStart && i <= viewEnd) {
          items.push(
            <MainViewLink
              key={`${this.props.keyPrefix}${i}`}
              className={classes}
              to={to}
              params={params}
              query={queryParams}
              {...linkProps}
            >
              <span className="number">{i}</span>
            </MainViewLink>,
          );
        }
      } else if (!this.context.spMode && this.props.lastPage > 4) {
        if (i >= viewStart && i <= viewEnd) {
          items.push(
            <MainViewLink
              key={`${this.props.keyPrefix}${i}`}
              className={classes}
              to={to}
              params={params}
              query={queryParams}
              {...linkProps}
            >
              <span className="number">{i}</span>
            </MainViewLink>,
          );
        }
      } else {
        items.push(
          <MainViewLink
            key={`${this.props.keyPrefix}${i}`}
            to={to}
            params={params}
            query={queryParams}
            className={classes}
            {...linkProps}
            // onClick={() => this.pagerClick(i)}
          >
            <span className="number">{i}</span>
          </MainViewLink>,
        );
      }
    }
    if (!items.length) {
      items = null;
    }

    // ボタンを短くするか
    // TODO: ボタンを短くする処理は不要かもしれないので要検証。最大ボタン表示数が4つなら不要と思われる（figmaだと4つ）
    let btnShorty = false;
    if (!_.isEmpty(items)) {
      btnShorty = (this.context.spMode && items.length > 4) || (!this.context.spMode && items.length > 4) ? true : false;
    }

    const prevLinkProps = {};
    const nextLinkProps = {};
    if (_.isNumber(_.get(this.props, "nofollowThreshold")) && this.props.nofollowThreshold >= this.props.currentPage - 1) {
      prevLinkProps.rel = 'nofollow';
    }
    if (_.isNumber(_.get(this.props, "nofollowThreshold")) && this.props.nofollowThreshold <= this.props.currentPage + 1) {
      nextLinkProps.rel = 'nofollow';
    }

    return (
      <React.Fragment>
        <div className={classnames('pager', { sliderimg: this.props.imglist })}>
          {this.props.lastPage > 1 ? (
            this.props.currentPage > 1 ? (
              <MainViewLink
                className={classnames('button secondary medium bold radius prev-button', { 'btn-shorty': btnShorty })}
                to={to}
                params={params}
                query={prevParams}
                {...prevLinkProps}
                // onClick={this.prevBtnClick}
              >
                <span className="">前へ</span>
              </MainViewLink>
            ) : (
              <div className="button prev-button blank"> </div>
            )
          ) : null}
          {this.props.imglist ? (
            <span className="pagenation-number-imglist">
              <span>
                {this.props.currentPage}/{this.props.lastPage}
              </span>
            </span>
          ) : (
            <div className="pager-list">{items}</div>
          )}
          {this.props.lastPage > 1 ? (
            this.props.currentPage !== this.props.lastPage ? (
              <MainViewLink
                className={classnames('button secondary medium bold radius next-button', { 'btn-shorty': btnShorty })}
                to={to}
                params={params}
                query={nextParams}
                // onClick={this.nextBtnClick}
                {...nextLinkProps}
              >
                <span className="">次へ</span>
              </MainViewLink>
            ) : (
              <div className="button next-button blank"> </div>
            )
          ) : null}
        </div>
        {!this.props.imglist &&
          (this.props.lastPage > 4) && (
            <p className="pagination_number">
              <span className="pagination_current">{this.props.currentPage}</span>/
              <span className="pagination_last">{this.props.lastPage}</span>
            </p>
          )}
      </React.Fragment>
    );
  }
}

export default withModalContext(PagerBlock);
